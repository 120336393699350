import React from "react";
import frente from '../../assets/image/Fotos Hotel/Frente/frentehotel.jpeg';
import lado from '../../assets/image/Fotos Hotel/Frente/lado.png';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const Termos = () => {
    const handleDownloadPDF = () => {
        const doc = new jsPDF();

        // Configurações de fonte
        doc.setFont("Times", "normal");
        doc.setFontSize(14);

        // Título centralizado
        doc.setFontSize(18);
        doc.text("A U T O R I Z A Ç Ã O", 105, 20, { align: "center" });

        // Conteúdo formatado
        doc.setFontSize(12);
        const marginLeft = 20;
        let currentHeight = 40;

        const lines = [
            "Eu, ................................................................................. brasileiro(a),",
            "portador(a) do RG ...................................., CPF n° ....................................,",
            "residente e domiciliado a Rua/Av. .................................................................",
            "N°: .........., Bairro: .........................., na Cidade: ..................................................",
            "",
            "e ................................................................................. brasileiro(a),",
            "portador(a) do RG ...................................., CPF n° ....................................,",
            "residente e domiciliado a Rua/Av. .................................................................",
            "",
            "RESPONSÁVEIS LEGAIS POR: ...........................................................",
            "",
            "AUTORIZAMOS O/A SR.(a) ...............................................................",
            "a hospedar-se na companhia de .......................................................",
            "Rg n° ...................................., CPF n° ....................................",
            "",
            "consoante estabelece a Lei Federal 8.069/1990 (Estatuto da Criança e do Adolescente - ECA),",
            "em seu artigo 82, que assim dispõe:",
            "",
            "\"É proibida a hospedagem de criança ou adolescente em hotel, motel, pensão ou estabelecimento",
            "congênere, salvo se autorizado ou acompanhado pelos pais ou responsável.\"",
            "",
            "A presente autorização tem prazo de validade de .......... dias, a contar desta data.",
            "",
            "............ de .......... de 20.........."
        ];

        // Adicionar cada linha ao PDF
        lines.forEach((line) => {
            doc.text(line, marginLeft, currentHeight);
            currentHeight += 10; // Ajusta o espaçamento entre as linhas
        });

        // Salvar o PDF
        doc.save("autorizacao_hospedagem.pdf");
    };


    return (
        <>
            {/* Main Content */}
            <main id="main">
                <Container className="py-5">
                    <Row>
                        <Col lg={6} className="mb-3">
                            <Card className="shadow-sm p-4 border-0">
                                <Card.Body>
                                    <h2 className="mb-4">Termos de Uso - Hotel Marechal Express</h2>
                                    <section className="mb-4">
                                        <h4>1. Reservas e Estadias:</h4>
                                        <p>
                                            As reservas estão sujeitas à disponibilidade e políticas
                                            específicas de cancelamento. Certifique-se de revisar as
                                            condições de reserva ao confirmar sua estadia.
                                        </p>
                                    </section>

                                    <section className="mb-4">
                                        <h4>2. Política de Cancelamento:</h4>
                                        <p>
                                            Cancelamentos e alterações de reserva podem estar sujeitos a
                                            taxas. Entre em contato para saber sobre nossa política de cancelamento para obter
                                            informações detalhadas.
                                        </p>
                                    </section>

                                    <section className="mb-4">
                                        <h4>3. Check-in e Check-out:</h4>
                                        <p>
                                            O check-in está disponível a partir das 13:00hrs e o check-out
                                            deve ser concluído até 10:00hrs.  Entre em contato para saber sobre taxas adicionais para check-out tardio.
                                        </p>
                                    </section>

                                    <section className="mb-4">
                                        <h4>4. Condições de Pagamento:</h4>
                                        <p>
                                            Aceitamos diversas formas de pagamento. Certifique-se de
                                            fornecer informações de pagamento válidas durante a reserva.
                                        </p>
                                    </section>

                                    <section className="mb-4">
                                        <h4>5. Política de Privacidade:</h4>
                                        <p>
                                            Suas informações pessoais são tratadas com o mais alto padrão
                                            de segurança e confidencialidade. Consulte nossa Política de
                                            Privacidade para obter detalhes sobre como suas informações
                                            são coletadas e utilizadas.
                                        </p>
                                    </section>

                                    <section className="mb-4">
                                        <h4>6. Uso Adequado das Instalações:</h4>
                                        <p>
                                            Solicitamos que todos os hóspedes utilizem as instalações do
                                            hotel de maneira adequada e respeitosa. Danos intencionais
                                            serão responsabilidade do hóspede.
                                        </p>
                                    </section>

                                    <section className="mb-4">
                                        <h4>7. Política de Não Fumantes:</h4>
                                        <p>
                                            O Hotel Marechal Express é uma propriedade livre de fumo. É
                                            proibido fumar em todas as áreas internas. Taxas podem ser
                                            aplicadas para limpeza de quartos devido ao tabagismo.
                                        </p>
                                    </section>

                                    <section>
                                        <h4>8. Responsabilidade por Pertences:</h4>
                                        <p>
                                            O hotel não se responsabiliza por perda, roubo ou dano aos
                                            pertences pessoais dos hóspedes. Utilize os cofres disponíveis
                                            nos quartos, quando disponíveis.
                                        </p>
                                    </section>
                                </Card.Body>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card className="shadow-sm p-4 border-0">
                                <Card.Body id="authorization-content">
                                    <section className="mb-4">
                                        <h3>AUTORIZAÇÃO PARA HOSPEDAGEM:</h3>
                                        <p>
                                            No artigo 82, do Estatuto da Criança e do Adolescente, está contida a restrição com relação à hospedagem de criança ou adolescente, nos seguintes termos:
                                        </p>
                                        <blockquote>
                                            <strong>
                                                Art. 82. É proibida a hospedagem de criança ou adolescente em hotel, motel, pensão ou estabelecimento congênere, salvo se autorizado ou acompanhado pelos pais ou responsável.
                                            </strong>
                                        </blockquote>
                                        <p>
                                            Assim, a lei deixou claro que somente acompanhado pelos pais ou responsável, a criança ou adolescente poderá se hospedar. Já no artigo 250 do ECA foi prevista a sanção aos estabelecimentos que não cumprirem a determinação da lei:
                                        </p>
                                        <blockquote>
                                            <strong>
                                                Art. 250. Hospedar criança ou adolescente, desacompanhado dos pais ou responsável ou sem autorização escrita destes, ou da autoridade judiciária, em hotel, pensão, motel ou congênere: Pena – multa; em caso de reincidência, fechamento por até 15 dias; e, se reincidente em 30 dias, fechamento definitivo.
                                            </strong>
                                        </blockquote>
                                    </section>
                                <p>
                                    Este é o documento oficial de autorização conforme o Estatuto da
                                    Criança e do Adolescente (ECA). Você pode baixar o PDF clicando
                                    no botão abaixo.
                                </p>
                                </Card.Body>
                                <Button variant="secondary" onClick={handleDownloadPDF}>
                                    Baixar Autorização em PDF
                                </Button>
                            </Card>

                            <br />
                            <Card className="shadow-sm border-0">
                                <Card.Img variant="top" src={frente} alt="Frente do Hotel" />
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </main>
        </>
    );
};

export default Termos;
